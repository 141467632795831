import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import DoctorIntro from "@/common/doctor-intro";
import CircleDetails, { DrCircleGrid } from "@/doctor-page/dr-circle";
import DrEducation, {
  DrContainerGrid,
  DrEducationUl,
  DrEducationWrapperWidth,
} from "@/doctor-page/dr-education";
import { theme } from "@/GlobalStyles";
import { AltLayout } from "layouts/AltLayout";

const DrBlerta: Page = () => {
  return (
    <AltLayout>
      <AlternativeHeroBanner
        PageName="About Dr Blerta"
        NavigateFrom="Home"
        NavigateTo="About Us"
        IntroHeader="Meet Our Doctor"
        BgHero="dr-blerta-hero.jpg"
        introPara="Dr. Blerta loves to make people smile. Literally. When your family
        visits the office, you will be told a joke. You will be expected to
        laugh. Don’t worry, we’re just kidding… Or are we?"
      ></AlternativeHeroBanner>
      <DoctorIntro
        image="dr-blerta-2.jpg"
        drName="DR. BLERTA"
        drPost="DMD"
        description="Dr. Blerta was born and raised in the small country of Albania. She moved with her family to the United States in 2008. After residency, she moved to Nashville and has been calling herself a proud Nashvillian ever since. Her favorite part about practicing orthodontics is getting to know the patients and their families. She loves to witness the confidence that orthodontic patients (young and not so young) gain after completing their treatment! When she is not in the office Dr. Blerta likes to spend time with her husband, Erik, daughters, Anna and Nora and her 2 mini labradoodles Ove and Alfie. She loves reading, trying new restaurants and napping."
        backgroundColor={theme.colors.white}
        flexDirection="row-reverse"
        MobileFlexDirection="row-reverse"
      />
      <DrEducationWrapperWidth>
        <DrContainerGrid>
          <DrEducation image="education.png" heading="Educational Background">
            <DrEducationUl>
              <li>
                Graduated with summa cum laude honors from University of South
                Florida
              </li>
              <li>
                Graduated with high honors from Nova Southeastern University
                College of Dentistry
              </li>
              <li>
                Induction in the Omicron Kappa Upsilon dental honor society
              </li>
              <li>
                The recipient of the BOO award (best case treated by a resident)
                at Texas A&M College of Dentistry Orthodontic Residency
              </li>
              <li>Board Certified in Orthodontics</li>
            </DrEducationUl>
          </DrEducation>
          <DrEducation image="awards.png" heading="Awards & Achievements">
            <DrEducationUl>
              <li>
                Received Bachelor of Science in Biomedical Sciences from
                University of South Florida
              </li>
              <li>
                Received Doctor of Dental Medicine degree from Nova Southeastern
                University College of Dentistry
              </li>
              <li>
                Completed Orthodontic Residency and received Master in Dental
                Science at Texas A&M College of Dentistry
              </li>
            </DrEducationUl>
          </DrEducation>
          <DrEducation image="membership.png" heading="Dental Memberships">
            <DrEducationUl>
              <li>American Association of Orthodontics</li>
              <li>American Dental Association</li>
              <li>Tennessee Dental Association</li>
              <li>Gaylord Orthodontic Alumni Association</li>
            </DrEducationUl>
          </DrEducation>
        </DrContainerGrid>
      </DrEducationWrapperWidth>
      <DrCircleGrid>
        <CircleDetails
          text="What do you do outside of the dental office?"
          bgImage="blerta-family.jpg"
        >
          I spend a lot of time with my family. We like to go to the park, zoo
          and adventure science center. My husband and I like to try new
          restaurants in downtown Nashville. It is fun to be a tourist in your
          own city! I am an avid reader and I am always looking for new books
          recommendations.
        </CircleDetails>
        <CircleDetails
          text="What is your most rewarding part of your job?"
          bgImage="kid-in-hoodie.jpg"
        >
          As cliche as it might sound, I love it when we finish treatment and
          patient see their brand new smile for the first time.
        </CircleDetails>
        <CircleDetails
          text="Who’s your favorite Super Hero?"
          bgImage="hammer-of-thor.jpg"
        >
          Thor
        </CircleDetails>
        <CircleDetails
          text="What's something your patients may be surprised to learn about you?"
          bgImage="soccer-game.jpg"
        >
          I used to be fluent in 5 languages. I am fluent in only two now but
          can still get by in France, Italy and Spain.
        </CircleDetails>
        <CircleDetails
          text="What's your favorite food?"
          bgImage="grilled-fish.jpg"
        >
          Any type of seafood. And Dr. Sam’s homemade ice cream of course!
        </CircleDetails>
      </DrCircleGrid>
      {/* <Gallery /> */}
    </AltLayout>
  );
};

export default DrBlerta;
